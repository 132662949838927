import React from "react";
import { Button } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
const pillsVid = require("../../assets/pillsVideo.mp4");


const HeroVid = () => {
  return (
    <div className="relative h-screen overflow-hidden">
      {/* Video Background */}
      <video
        className="w-full h-full object-cover absolute top-0 left-0 opacity-80 z-0"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={pillsVid} type="video/mp4" />
      </video>

      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-20 z-0"></div>
      {/* Content */}
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white z-10 p-8">
        <h1 className="text-4xl md:text-6xl font-bold mb-4 underline  decoration-[#49a54e]">
          Welcome to MiNutri Care
        </h1>
        <div className="sm:max-w-[75%] md:max-w-[70%] flex flex-col justify-center items-center">
          <p className="text-lg md:text-xl mb-8">
            Our Pharmaceutical and Nutraceutical products are the best in
            quality. Whether you operate on a global or regional level, we can
            consistently provide comprehensive logistics, and technical support
            for your pharmaceutical and nutritional market needs
          </p>
          <Button variant="gradient" size="lg" className="mt-4" color="green">
            <Link to={'/products'}>Explore Products</Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HeroVid;
