import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import sucrose from "./SucroseTab";
import mannitol from "./MannitolTab";
import nps from "./NpsTab";
import others from "./OthersTab";

export function TabsCustomAnimation() {
  const data = [
    {
      label: "Sucrose",
      value: "sucrose",
      desc: sucrose(),
    },
    {
      label: "Mannitol",
      value: "mannitol",
      desc: mannitol(),
    },

    {
      label: "NON PARIEL SEEDS (NPS)",
      value: "nps",
      desc: nps(),
    },

    {
      label: "OTHER EXCIPIENTS",
      value: "otherExcipients",
      desc: others(),
    },
  ];

  return (
    <Tabs
      id="custom-animation"
      value="sucrose"
      className="font-raleway text-lg text-black z-0"
    >
      <TabsHeader> 
        {data.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            className="font-raleway text-lg text-black z-0"
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody
        animate={{
          initial: { y: 250 },
          mount: { y: 0 },
          unmount: { y: 250 },
        }}
      >
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}
