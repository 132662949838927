import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
const logo = require("../../assets/logo.jpeg");

function NavList() {
  return (
    <ul className="my-2 flex flex-col gap-2 md:mb-0 md:mt-0 md:flex-row md:items-center md:gap-6">
      <Typography
        as="li"
        variant="h6"
        color="blue-gray"
        className="p-1 font-semibold"
      >
        <Link
          className="flex items-center hover:text-[#49a54e] transition-colors"
          to={"/"}
        >
          About
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="h6"
        color="blue-gray"
        className="p-1  font-semibold"
      >
        <Link
          className="flex items-center hover:text-[#49a54e] transition-colors"
          to={"/products"}
        >
          Products
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="h6"
        color="blue-gray"
        className="p-1 font-semibold"
      >
        <a
          href="#footer"
          className="flex items-center hover:text-[#49a54e] transition-colors"
        >
          Contact Us
        </a>
      </Typography>
    </ul>
  );
}

export default function NavbarSimple() {
  const [openNav, setOpenNav] = React.useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Navbar className="mx-auto w-full max-w-none rounded-none px-6 py-3 shadow-lg">
      <div className="flex items-center justify-between text-blue-gray-900">
        <div className="flex items-center justify-center">
          <div className="mr-2">
            <Link to={'/'}>
            <img
              src={logo}
              alt="MINUTRI CARE Logo"
              className=" h-[50px] sm:h-[65px]"
            />
            </Link>
          </div>
        </div>

        <div className="hidden md:block md:flex">
          <NavList />
        </div>

        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent md:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
      </Collapse>
    </Navbar>
  );
}
