
import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
const pillsOne = require("../../assets/bannerpills.png");

const About = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const aboutUsSection = document.getElementById("aboutUs");
      if (aboutUsSection) {
        const rect = aboutUsSection.getBoundingClientRect();
        setIsVisible(rect.top < window.innerHeight * 0.75);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section
      id="aboutUs"
      className={`flex flex-col items-center justify-center px-4 mt-8 md:mt-10 lg:mt-14 transition-opacity duration-1000 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className=" mx-auto">
        <div className="flex flex-col md:flex-row md:space-x-12">
          <div className="md:w-[30%] ">
            <img
              src={pillsOne}
              alt="Pills"
              className="w-full h-auto rounded-lg mb-6 md:mb-0"
            />
          </div>
          <div className="md:w-1/2">
            <Typography className="text-5xl font-semibold mb-4 font-raleway underline decoration-[#49a54e]">
              About Us
            </Typography>
            <Typography className="text-xl font-semibold mb-4 font-raleway">
              Your source for Pharma, Nutraceutical, Feed, and
              Food Industries
            </Typography>

            <Typography
              variant="paragraph"
              className="text-xl font-medium mb-4 "
            >
              <span className=" font-bold">Welcome to MiNutri Care </span> A
              flourishing trading firm that has earned its reputation as the
              premier partner in the Pharma, Nutraceutical, Feed, and Food
              industries across the Asian subcontinent. Now, we are excited to
              expand our operations into North America, bringing our successful
              expertise to new horizons.
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xl font-medium mb-4 "
            >
              <span className=" font-bold"> Unrivaled Expertise:</span> Our team
              of experts boasts unparalleled knowledge in solvents, food colors,
              and flavors, ensuring that we deliver products of the highest
              quality. We take pride in our ability to surpass expectations,
              providing tailored solutions that cater to the unique needs of
              each client.
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xl font-medium mb-4 "
            >
              <span className=" font-bold"> Expanding Horizons:</span> As we
              step into North America, our vision remains resolute - to offer
              the same level of professionalism and integrity that has been the
              hallmark of MiNutri Care's success. We are dedicated to building
              strong partnerships and delivering unrivaled value to our new
              clients in these regions. investments.
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xl font-medium mb-4 "
            >
              <span className=" font-bold">
                Responsible Sourcing, Competitive Edge:
              </span>
              At MiNutri Care, we uphold ethical practices in all our endeavors.
              Our commitment to responsible sourcing enables us to deliver
              products that stand out for their integrity and quality. What's
              more, our competitive pricing ensures that you get exceptional
              value for your investments.
            </Typography>

            <Typography className="text-3xl font-semibold mb-8 font-raleway text-[#49a54e]">
              Discover the MiNutri Care advantage today!
            </Typography>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
