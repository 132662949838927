import ProdChip from "./ProdChip";
import { Typography } from "@material-tailwind/react";

const sucrose = () => (
  <div className="p-2 text-black">
    <Typography
      variant="paragraph"
      className="text-xl font-medium mb-8  text-black"
    >
      Our sucrose crystals are pure and clear that is widely used for dry
      suspension, clear solution, tablet coating, sachet products, and
      manufacturing of pellets. We deal in a range of high-quality Sucrose
      granulation sizes to meet the requirements of the pharma industry
    </Typography>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 text-black">
      <ProdChip
        className="col-md-4 col-xs-12"
        title="SUCROSE - SPECIAL PARTICLE SIZE"
      />
      <ProdChip className="col-md-4 col-xs-12" title="LOW ENDOTOXIN SUCROSE" />
      <ProdChip className="col-md-4 col-xs-12" title="MILLED SUCROSE" />
      <ProdChip className="col-md-4 col-xs-12" title="CONFECTIONER'S SUGAR" />
      <ProdChip className="col-md-4 col-xs-12" title="COMPRESSIBLE SUGAR" />
      <ProdChip
        className="col-md-4 col-xs-12"
        title="LIGHT BROWN PHARMA SUGAR"
      />
      <ProdChip className="col-md-4 col-xs-12" title="SUCROSE - LR" />
      <ProdChip className="col-md-4 col-xs-12" title="SUCROSE - AR" />
    </div>
    <div className="mb-4">
      <Typography variant="h5" className="mb-4">
        Features
      </Typography>
      <ul className="list-disc px-4 text-base font-medium">
        <li>
          Features of Sucrose: Sweet, carbohydrate, disaccharide, found in
          plants.
        </li>
        <li>
          Free Flowing: Easy to pour or dispense, does not clump or stick
          together.
        </li>
        <li>
          Rapidly Soluble: Dissolves quickly in liquid, making it convenient for
          various applications.
        </li>
        <li>
          Uniform Crystal/Grain Size: Consistent particle size, ensuring even
          distribution and performance in different processes.
        </li>
        <li>
          Available in 2000/75 Microns and 10/200 ASTM: Available in different
          particle size options for different applications or requirements.
        </li>
        <li>
          100% Free from Metallic Elements and Black Specs: Ensures purity and
          quality, suitable for use in sensitive applications where contaminants
          are not desirable.
        </li>
      </ul>
    </div>
    <div className="mb-4">
      <Typography variant="h5" className="mb-4">
        Applications
      </Typography>
      <ul className="list-disc px-4 text-base font-medium">
        <li>
          Suspension, clear solutions, and pallets: The clear & perfect crystals
          of sucrose having high purity are recommended for dry suspension &
          clear solution. Also used in the manufacturing of pellets.
        </li>
        <li>
          Carrying agent: Used in parental formulations as a carrying agent,
          particularly in intravenous and intramuscular injectables.
        </li>
        <li>
          Protein stabilizer: As a protein stabilizer, nutritional supplement in
          the bio-pharmaceutical application.
        </li>
        <li>
          Oil-based suspension: Ideal choice where rapid dissolution is
          required. Best suited for oil-based suspension.
        </li>
        <li>
          Granulation, sachet filling, and coating: Used for granulation, sachet
          filling, and coating. Very fine powder which makes it dissolve quickly
          in water. It is also used for the compressibility of products. Used as
          a diluent in tablets.
        </li>
        <li>
          Tablet Making: A white, granular, free-flowing powder that is used for
          tablet making (Chewable, effervescent and orodispersable tablets) in
          the pharmaceutical industry.
        </li>
        <li>
          Sachet filling: Used for sachet filling for mixing with active
          ingredients and other food products. Also used as a coloring aid.
        </li>
        <li>
          Laboratory reagents: High-purity sucrose crystals are recommended as
          laboratory reagents. Also finds application in tissue culture and
          biotech products.
        </li>
        <li>
          Analytical preparation: Used in analytical preparation, where high
          purity of sucrose is required.
        </li>
      </ul>
    </div>
  </div>
);

export default sucrose;
