
import "./app.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from "./page/Home";
import Products from "./components/Products/Products";

export default function App() {
  return (
    <Router>
    <Switch>
    <Route exact  path="/" component={Home}/>
          <Route path="/products" component={Products}/>
          </Switch>
    </Router>
  );
}
