import React from "react";
import NavbarSimple from "../components/Navbar/Navbar";
import HeroVid from "../components/HeroOne/HeroVid";
import About from "../components/About/About";
import Footer from "../components/Footer/Footer";
import FloatingActionButton from "../components/FloatingAction/FloatingActionButton";

export default function Home() {
  return (
    <div className=" font-raleway m-auto min-w-[375px] min-h-screen flex flex-col">
      <NavbarSimple />
      <main className="flex-grow">
        <HeroVid />
        <div className="h-1  bg-[#49a54e]"></div>
        <About />
      </main>
      <div className="h-1  bg-[#49a54e]"></div>
      <Footer />
      <FloatingActionButton />
    </div>
  );
}
