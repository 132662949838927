import ProdChip from "./ProdChip";
import { Typography } from "@material-tailwind/react";

const nps = () => (
  <div className="p-2 text-black">
    <Typography
      variant="paragraph"
      className="text-xl font-medium mb-8  text-black"
    >
      Our high-quality non-pareil seeds serve as inert cores in capsule and
      tablet formulations, especially multi-particulate, sustained-release
      formulations. They are also utilized to serve as the substrate for a
      drug's coating, which is typically followed by a release-modifying polymer
      coating. Non-Pareil Seeds are spherical, uniformly sized particles. They
      are practically inert, odourless, and tasteless white granules with a
      homogeneous appearance. Non-Pareil seeds are composed mostly of inert
      components such as starch and lactose. Non-Pareil seeds are typically
      colourless but can be provided in a variety of colours upon request.
      Non-Pareil seeds are stable at room temperature.
    </Typography>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 text-black">
      <ProdChip className="col-md-4 col-xs-12" title="SUGAR GLOBULES" />
      <ProdChip className="col-md-4 col-xs-12" title="SUGAR SPHERES" />
      <ProdChip className="col-md-4 col-xs-12" title="TARTARIC ACID SPHERES" />
      <ProdChip className="col-md-4 col-xs-12" title="MANNITOL SPHERES" />
      <ProdChip className="col-md-4 col-xs-12" title="MCC SPHERES" />
    </div>
    <div className="mb-4">
      <Typography variant="h5" className="mb-4">
        Features
      </Typography>
      <ul className="list-disc px-4 text-base font-medium">
        <li>
          Rapidly Soluble: Non-pareil seeds dissolve quickly, making them ideal
          for pharmaceutical formulations that require fast dissolution rates.
        </li>
        <li>
          High-Quality Standards: Non-pareil seeds adhere to stringent quality
          standards, ensuring their purity, efficacy, and safety for use in
          pharmaceutical products.
        </li>
        <li>
          Free Flowing: Non-pareil seeds have excellent flowability, allowing
          for easy handling and processing during pharmaceutical manufacturing.
        </li>
        <li>
          Uniform Crystal/Grain Size: Non-pareil seeds are consistent in size
          and shape, providing uniformity in pharmaceutical formulations and
          ensuring accurate dosing.
        </li>
        <li>
          Practically Inert: Non-pareil seeds are chemically stable and do not
          react with other ingredients in pharmaceutical formulations, making
          them ideal for maintaining the stability and integrity of the final
          product.
        </li>
        <li>
          Tasteless: Non-pareil seeds have no discernible taste, making them
          suitable for use in oral pharmaceutical products without affecting the
          taste of the medication.
        </li>
        <li>
          Odorless: Non-pareil seeds are devoid of any odor, making them ideal
          for use in pharmaceutical formulations where odor could affect the
          sensory experience of the end user.
        </li>
      </ul>
    </div>
    <div className="mb-4">
      <Typography variant="h5" className="mb-4">
        Applications
      </Typography>
      <ul className="list-disc px-4 text-base font-medium">
        <li>
          Free-flowing, dry spherical masses of 100% sucrose are used widely in
          the pharmaceutical industry.
        </li>
        <li>Used as inert core in capsule and tablet formulation</li>
        <li>
          Used as a functional starter core for extended-release formulations to
          reduce the pH-dependent solubility of drugs
        </li>
        <li>Sugar-free pellets used for drug layering</li>
        <li>Used as inert core in capsule and tablet formulation</li>
      </ul>
    </div>
  </div>
);

export default nps;
