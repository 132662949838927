import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { clsx } from "clsx";

const ProdChip = (props) => (
  <div
    className={clsx(
      "border-transparent hover:shadow-lg hover:border-b-2   hover:border-[#49a54e] hover:uppercase rounded-md w-full h-[55px] flex items-center p-2  cursor-pointer bg-blue-gray-50 bg-opacity-60",
      props.className
    )}
  >
    <Typography className="text-md font-semibold ">
      <Link to={"/"}>{props.title}</Link>
    </Typography>
  </div>
);

export default ProdChip;
