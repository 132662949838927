import ProdChip from "./ProdChip";
import { Typography } from "@material-tailwind/react";

const others = () => (
  <div className="p-2 text-black">
    <Typography
      variant="paragraph"
      className="text-xl font-medium mb-8  text-black"
    >
      An excipient is a substance that is formulated with the active ingredient
      of a medication for the purpose of long-term stabilization, bulking up
      solid formulations that contain potent active ingredients in small amounts
      (thus often referred to as "bulking agents," "fillers," or "diluents"), or
      conferring a therapeutic enhancement on the active ingredient in the final
      dosage form, such as enhancing drug absorption, reducing viscosity, or
      enhancing solubility. Excipients can also be advantageous in the
      production process, aiding in the handling of the active ingredient by
      improving powder flowability or non-stick characteristics, as well as
      aiding in vitro stability by preventing denaturation or aggregation during
      the anticipated shelf life.
    </Typography>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 text-black">
      <ProdChip className="col-md-4 col-xs-12" title="FLO-STARCH™" />
      <ProdChip className="col-md-4 col-xs-12" title="MALTODEXTRIN" />
      <ProdChip className="col-md-4 col-xs-12" title="S-COAT™" />
      <ProdChip className="col-md-4 col-xs-12" title="TARTARIC ACID POWDER" />
      <ProdChip
        className="col-md-4 col-xs-12"
        title="CALCIUM SULPHATE DIHYDRATE"
      />
      <ProdChip className="col-md-4 col-xs-12" title="LIQUID SORBIDOL" />
    </div>
    <div className="mb-4">
      <Typography variant="h5" className="mb-4">
        Features
      </Typography>
      <ul className="list-disc px-4 text-base font-medium">
        <li>
          We comply with IH specifications, GMP (Good Manufacturing Practices),
          Drug License, Kosher, Halal, CFDA (China Food and Drug
          Administration), FSSC 22000 (Food Safety System Certification), TGA
          (Therapeutic Goods Administration), and other quality requirements.
        </li>
        <li>
          We also makes all necessary quality documents, such as US DMF (Drug
          Master File), readily available to ensure the highest quality
          standards for their excipients.
        </li>
      </ul>
    </div>
    <div className="mb-4">
      <Typography variant="h5" className="mb-4">
        Applications
      </Typography>
      <ul className="list-disc px-4 text-base font-medium">
        <li>
          It is used as a carrier in the food, pharmaceutical & nutraceutical
          industry.
        </li>
        <li>
          It is used to mask taste and odor, act as a seal coating and moisture
          barrier, and improve the stability of the product.
        </li>
        <li>Used as a stabilizer in the product/API stabilizer.</li>
        <li>Used as a filler, as an excipient, or as a Calcium supplement.</li>
        <li>
          It is used as a filler and raw material for liquid syrup formation.
        </li>
      </ul>
    </div>
  </div>
);

export default others;
