import React from 'react';
import { Link } from 'react-router-dom';
const logo = require('../../assets/logo.jpeg')

const Footer = () => {
  return (
    <footer id='footer' className="bg-white py-8">
      <div className=" mx-auto flex flex-col md:flex-row items-center justify-between px-4">
        <div className="flex items-center justify-center md:justify-start">
          <img src={logo} alt="MiNutri Care " className="mr-2 w-24" />
          {/* <p className="text-lg font-medium">Your Premier Trading Partner </p> */}
        </div>
        <div className="mt-4 md:mt-0">
          <p className="text-sm mt-2"><span className=' font-bold'>Email:</span> info@minutricare.com, sales@minutricare.com</p>
          <p className="text-sm mt-2"><span className='font-bold'>Phone:</span> +1 6478700109, +1 6472615537</p>
        </div>
        <div className="mt-4 md:mt-0">
          <div className="flex mt-2">
            <a
              href="#aboutUs"
              className="text-gray-800 mr-4 hover:text-gray-600"
            >
              About
            </a>
            <Link
              to={'/products'}
              className="text-gray-800 mr-4 hover:text-gray-600"
              target="_blank"
              rel="noopener noreferrer"
            >
             Products
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 py-3 mt-12">
        <p className="text-sm text-gray-600">
          &copy; {new Date().getFullYear()} MiNutri Care All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
