import ProdChip from "./ProdChip";
import { Typography } from "@material-tailwind/react";

const mannitol = () => (
  <div className="p-2 text-black">
    <Typography
      variant="paragraph"
      className="text-xl font-medium mb-8  text-black"
    >
      We provide high-quality Mannitol that meets the needs of various
      industries, including pharmaceuticals, and more. Mannitol is a polyol
      (sugar alcohol) and an isomer of sorbitol. Mannitol is manufactured at a
      state-of-the-art facilities and comply with stringent quality standards to
      offer numerous benefits for various applications. Mannitol is an active
      ingredient and pharmaceutical excipient that serves as a tablet/capsule
      diluent, sweetener, therapeutic/tonicity agent, and plasticizer. While not
      as widely used as lactose monohydrate, calcium carbonate, or starch,
      mannitol has gained prominence in recent years, and more and more NPD
      projects are evaluating it as a potential excipient.
    </Typography>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 text-black">
      <ProdChip className="col-md-4 col-xs-12" title="MANNITAB™ SD 1" />
      <ProdChip className="col-md-4 col-xs-12" title="MANNITAB™ SD 2" />
      <ProdChip className="col-md-4 col-xs-12" title="MANNITAB™ F1" />
      <ProdChip className="col-md-4 col-xs-12" title="MANNITAB™ F2" />
      <ProdChip className="col-md-4 col-xs-12" title="MANNITAB™ F3" />
      <ProdChip className="col-md-4 col-xs-12" title="MANNITAB™ RS" />
    </div>
    <div className="mb-4">
      <Typography variant="h5" className="mb-4">
        Features
      </Typography>
      <ul className="list-disc px-4 text-base font-medium">
        <li>
          Our Mannitol complies with various quality standards. This includes
          compliance with USP/BP/EP/USP/JP, which are recognized international
          pharmacopeias. We comply with other quality certifications such as GMP
          (Good Manufacturing Practices), Drug License, Kosher, and Halal.
        </li>
        <li>
          We package Mannitol products in 25 kg Fiber Drums, ensuring safe and
          hygienic storage.
        </li>
        <li>
          The shelf-life of mannitol is 5 years, providing a long-lasting and
          reliable excipient for various applications.
        </li>
      </ul>
    </div>
    <div className="mb-4">
      <Typography variant="h5" className="mb-4">
        Applications
      </Typography>
      <ul className="list-disc px-4 text-base font-medium">
        <li>
          Its favorable tableting qualities, cost-effectiveness, inertness to
          active medicinal components, low hygroscopicity, and safety are the
          primary causes.
        </li>
        <li>
          Mannitol sugar is an excipient that is used in granulation to make
          oral solid dosage forms. Because it has low hygroscopicity, chemical
          inertness, and effective compression tableting. It improves taste and
          mouthfeel in chewable, sublingual, and orodispersible tablets.
        </li>
        <li>
          Mannitol, a bulking ingredient in lyophilization, improves
          freeze-drying and product appearance and guarantees echogenicity.
          Mannitol forms an amorphous matrix during freeze drying, allowing
          water to escape and preventing sample collapse. It directs water
          sublimation and preserves proteins.
        </li>
        <li>
          Mannitol does not respond to Mallard's response and does not form
          hapten-carrier conjugates but activates immunological responses with
          proteins. Thus, it is employed as a bulking ingredient in food and
          medications to prevent API reactions.
        </li>
      </ul>
    </div>
  </div>
);

export default mannitol;
