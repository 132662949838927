import React, { useState } from "react";
import ContactForm from "./ContactForm";
import { Button } from "@material-tailwind/react";

const FloatingActionButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="fixed bottom-8 right-8 z-10">
      <Button
        onClick={handleButtonClick}
        variant="gradient"
        size="lg"
        color="green"
        className="shadow-lg transition-transform transform hover:scale-105"
      >
        <span> {isOpen ? "Close" : "Request Quote"}</span>
      </Button>
      {isOpen && <ContactForm onClose={() => setIsOpen(false)} />}
    </div>
  );
};

export default FloatingActionButton;
