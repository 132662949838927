import React from "react";
import NavbarSimple from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import FloatingActionButton from "../FloatingAction/FloatingActionButton";
import { TabsCustomAnimation } from "../Tabs/Tabs";
const pills = require("../../assets/pill_banner.jpeg");

export default function Products() {
  return (
    <div className=" font-raleway m-auto min-w-[375px] min-h-screen flex flex-col">
      <NavbarSimple />
      <main className="flex-grow">
        <div className="relative bg-gray-900 h-[290px]">
          <img
            src={pills}
            alt="Hero Background"
            className="object-cover w-full h-full absolute inset-0"
          />
          <div className="absolute inset-0 bg-black opacity-40"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <h1 className="text-white text-4xl sm:text-5xl md:text-6xl font-semibold text-center underline  decoration-[#49a54e]">
              Our Product Range
            </h1>
          </div>
        </div>
        <div className="h-1  bg-[#49a54e]"></div>
        <section>
          <TabsCustomAnimation />
        </section>
      </main>
      <div className="h-1  bg-[#49a54e]"></div>
      <Footer />
      <FloatingActionButton />
    </div>
  );
}
