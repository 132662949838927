import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";

const ContactForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
const formRef = useRef()
  const handleChange = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    emailjs
      .sendForm(
        "service_k1ez0b5",
        "template_toex8mo",
        formRef.current,
        "sXkDYIbPtRfcAOw5A"
      )
      .then((res) => {
        console.log(res);
        setIsSuccess(true);
        console.log("Form submitted:", formData);
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
        setTimeout(() => {
            setIsSuccess(false);
          }, 5000);
      })
      .catch((err) =>{ 
        console.log(err);
        setIsError(true);
      });
  };

  return (
<div className=" z-50 fixed bottom-20 right-1/4 w-1/2 bg-gray-100 p-6 rounded-lg shadow-lg animate-slide-up transform hover:scale-105 transition-all">
      <h3 className="text-2xl font-semibold text-green-800 mb-4">
        Get in Touch
      </h3>
      {isSuccess && (
        <div className="mb-4 bg-green-500 text-white p-2 rounded-md">
          🎉 Your message has been sent successfully! Thank you! 🎉
        </div>
      )}
      {isError && (
        <div className="mb-4 bg-red-500 text-white p-2 rounded-md">
          😢 Oops! Something went wrong. Please try again later. 😢
        </div>
      )}
      <form ref={formRef} onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col space-y-1">
          <label htmlFor="name" className="text-green-800 font-medium">
            Your Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="p-3 border border-green-400 rounded-md placeholder-green-400 focus:outline-none focus:ring-2 focus:ring-green-600"
            placeholder="Enter your full name"
            required
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="email" className="text-green-800 font-medium">
            Your Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="p-3 border border-green-400 rounded-md placeholder-green-400 focus:outline-none focus:ring-2 focus:ring-green-600"
            placeholder="Enter your email address"
            required
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="subject" className="text-green-800 font-medium">
            Subject
          </label>
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className="p-3 border border-green-400 rounded-md placeholder-green-400 focus:outline-none focus:ring-2 focus:ring-green-600"
            placeholder="Enter the subject of your message"
            required
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="message" className="text-green-800 font-medium">
            Message
          </label>
          <textarea
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            className="p-3 border border-green-400 rounded-md placeholder-green-400 focus:outline-none focus:ring-2 focus:ring-green-600"
            placeholder="Type your message here"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-gradient-to-br from-green-500 to-green-600 text-white px-4 py-3 rounded-md hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-600"
        >
          Submit Message
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
